import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { Howl, Howler } from "howler"
import UAParser from "ua-parser-js"

Howler.autoUnlock = false

const fade = (
  sound: Howl | undefined,
  to: number,
  duration: number,
  play: boolean
) => {
  if (!sound) return
  const { type: deviceType } = new UAParser(
    window.navigator.userAgent
  ).getDevice()

  if (deviceType === "mobile") {
    if (play) {
      sound.play()
    } else {
      sound.pause()
    }
  } else {
    sound?.fade(sound.volume(), to, duration)
  }
}

const volume = 1

export const SoundWindow: React.FC = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const howl = React.useRef<Howl>()
  const window = React.useRef<Howl>()

  const preloadWindow = React.useCallback(
    (autoPlay = false, preload = autoPlay ? true : "metadata") => {
      if (window.current) return
      window.current = new Howl({
        src: "/window.mp3",
        volume: 1,
        autoplay: autoPlay,
        preload: preload as any,
        sprite: {
          open: [0, 330],
          close: [330, 685],
        },
      })
    },
    []
  )

  React.useEffect(() => {
    if (isOpen) {
      const sources = ["/river_loop.mp3", "/river_loop.ogg"]
      if (!howl.current) {
        howl.current = new Howl({
          src: sources,
          loop: true,
          html5: true,
          autoplay: true,
          volume: 0,
        })
        preloadWindow(true)
        window.current?.load()
      }

      window.current?.play("open")
      fade(howl.current, volume, 400, true)
      if (typeof umami !== "undefined") umami?.track("🎵", { src: sources[0] })
    } else {
      fade(howl.current, 0, 800, false)
      window.current?.play("close")
    }
  }, [isOpen])

  // Clean up
  React.useEffect(
    () => () => {
      if (window.current) {
        window.current.unload()
        window.current = undefined
      }
      if (howl.current) {
        howl.current.unload()
        howl.current = undefined
      }
    },
    []
  )

  return (
    <button
      className={classNames("soundWindow", {
        "soundWindow--active": isOpen,
      })}
      type="button"
      aria-label="Play some soothing sounds"
      onClick={() => setIsOpen((s) => !s)}
      onMouseOver={() => preloadWindow(false, true)}
    >
      <StaticImage
        layout="fixed"
        width={200}
        height={200}
        loading="eager"
        imgClassName="soundWindow__image"
        className="soundWindow__image-container"
        alt="A picture of the Hastings seaside"
        src={"../images/river.jpg"}
      />
      <div className="soundWindow__inner" />
    </button>
  )
}
