import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { sortBy } from "lodash"
import { Audio } from "../components/Markdown"
import { SoundWindow } from "../components/SoundWindow"
import { AboutTeaser } from "../components/AboutTeaser"
import { getExcerpt } from "../excerptHelper"
import ReactMarkdown from "react-markdown"
import { YTPlaylistItem } from "../components/YTTPlaylist"
import { Stories } from "../components/Stories"

interface LatestItem {
  headingText: string
  date: string
  excerpt: string
  slug: string
  emoji: string
  attachment?: React.ReactNode
  target?: string
}

class Frontpage extends React.Component<MHPageProps> {
  render() {
    const { data } = this.props
    const latestNow = data.latestNow?.edges?.[0]?.node
    const latestMusic = data.latestMusic?.edges?.[0]?.node
    const latestRedesign: YTPlaylistItem =
      data.latestRedesigns?.edges?.[0]?.node
    const latestPost = data.latestPost?.edges?.[0]?.node

    const latestItems: LatestItem[] = []
    if (latestNow) {
      const { frontmatter } = latestNow
      latestItems.push({
        headingText: frontmatter.nowDate ?? frontmatter.formattedDate,
        excerpt: getExcerpt(latestNow, true),
        date: frontmatter.date,
        slug: latestNow.fields.slug,
        emoji: "🕓",
      })
    }
    if (latestRedesign) {
      latestItems.push({
        headingText: latestRedesign.snippet.title,
        excerpt: latestRedesign.snippet.description,
        date: latestRedesign.snippet.publishedAt,
        slug: `https://www.youtube.com/watch?v=${latestRedesign.snippet.resourceId.videoId}&list=${latestRedesign.snippet.playlistId}`,
        emoji: "🎛️",
        target: "_blank",
      })
    }
    if (latestMusic) {
      const { frontmatter } = latestMusic
      latestItems.push({
        headingText: frontmatter.title,
        excerpt: getExcerpt(latestMusic, true),
        date: frontmatter.date,
        slug: latestMusic.fields.slug,
        emoji: "🎵",
        attachment: frontmatter.audio && (
          <Audio controls className="n-spacing-top-tiny" preload="none">
            <source src={frontmatter.audio} type="audio/mpeg" />
          </Audio>
        ),
      })
    }
    if (latestPost) {
      const { frontmatter } = latestPost
      latestItems.push({
        headingText: frontmatter.title,
        excerpt: getExcerpt(latestPost, true),
        date: frontmatter.date,
        slug: latestPost.fields.slug,
        emoji: "✍️",
      })
    }

    return (
      <>
        <SEO />

        {/* <section className="n-type-larger n-vspacing-huge">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/713359440?h=9602a95f53"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
            style={{ width: "100%", aspectRatio: "16/9", height: "auto" }}
          ></iframe>
        </section> */}

        <section className="max-w-prose n-type-larger n-vspacing-huge">
          <h2 className="n-spacing-bottom-smaller">Latest…</h2>

          <nav>
            <ol>
              {sortBy(latestItems, (i) => new Date(i.date).valueOf())
                .reverse()
                .map((item) => (
                  <li
                    key={item.slug}
                    className="n-spacing-bottom-small gap-3 flex"
                  >
                    <div aria-hidden="true">{item.emoji}</div>
                    <div className="flex-auto">
                      <Link to={item.slug} target={item.target}>
                        <strong className="g-link">{item.headingText}</strong>{" "}
                        {item.excerpt}
                      </Link>

                      {item.attachment}
                    </div>
                  </li>
                ))}
            </ol>
          </nav>

          <h3 className="n-spacing-bottom-smaller">
            <Link to="/stories" className="g-link">
              Stories
            </Link>
          </h3>
          <Stories />
        </section>

        <section className="max-w-prose n-type-larger n-vspacing-huge ">
          <h2 className="n-spacing-bottom-smaller">Sound Design</h2>
          <ReactMarkdown>
            {data.sound.frontmatter.description ?? getExcerpt(data.sound)}
          </ReactMarkdown>
          <Link className="button n-spacing-top-small" to="/sound">
            View my Sound Design page
          </Link>
        </section>

        <section className="max-w-prose n-type-larger n-vspacing-huge">
          <h2 className="n-spacing-bottom-smaller">Web development</h2>
          <p>
            I currently work for{" "}
            <a
              className="g-link"
              target="_blank"
              rel="nofollow noreferer"
              href="https://boords.com"
            >
              Boords
            </a>{" "}
            (an&nbsp;online storyboarding tool) as a web developer. Web-wise I
            have a lot of experience doing Javascript-heavy front-end stuff, but
            I'm a bit of a generalist, so I have the occasional side project as
            well.
          </p>
          <Link className="button n-spacing-top-small" to="/work">
            Read about my web development work…
          </Link>
        </section>

        <section className="max-w-prose n-type-larger n-vspacing-huge">
          <h2 className="n-spacing-bottom-smaller">About me</h2>
          <AboutTeaser />
        </section>

        <aside className="n-vspacing-huge" style={{ textAlign: "right" }}>
          <SoundWindow />
        </aside>
      </>
    )
  }
}

export default Frontpage

export const pageQuery = graphql`
  fragment basicPageInfo on MdxConnection {
    edges {
      node {
        ...MHExcerpt
        fields {
          slug
          contentType
        }
        frontmatter {
          title
          audio
          date
          published
          updatedDate
          nowDate
          formattedDate: date(formatString: "MMMM YYYY")
        }
      }
    }
  }

  query GetFrontPage($publishedFilter: BooleanQueryOperatorInput) {
    site {
      siteMetadata {
        title
      }
    }
    sound: mdx(slug: { eq: "sound" }) {
      ...MHExcerpt
    }
    latestNow: allMdx(
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { contentType: { eq: "now" } }
        frontmatter: { published: $publishedFilter }
      }
    ) {
      ...basicPageInfo
    }
    latestMusic: allMdx(
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { contentType: { eq: "music" } }
        frontmatter: { published: $publishedFilter }
      }
    ) {
      ...basicPageInfo
    }
    latestRedesigns: allYoutubePlaylistItem(
      limit: 1
      sort: { fields: index, order: ASC }
    ) {
      edges {
        node {
          snippet {
            title
            description
            playlistId
            resourceId {
              videoId
            }
            publishedAt
          }
        }
      }
    }
    latestPost: allMdx(
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { contentType: { eq: "posts" } }
        frontmatter: { published: $publishedFilter }
      }
    ) {
      ...basicPageInfo
    }
  }
`
